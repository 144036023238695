<template>
  <a-upload
    name="file"
    :multiple="mult"
    :action="route"
    :headers="headers"
    :data="data"
    :file-list="fileList"
    @change="handleChange"
  >
    <a-button><a-icon type="upload" />选择文件</a-button>
  </a-upload>
</template>

<script>
export default {
  props: ["mult", "folder", "oldFiles"],
  data() {
    return {
      route: this.API + "/file/uploadFile",
      headers: {
        token: "",
      },
      data: {},
      fileList: [],
    };
  },
  created() {
    this.headers.token = this.$store.state.token;
    this.data.folder = this.folder || 'other';

    if (this.oldFiles) {
      for (let item of this.oldFiles) {
        this.fileList.push({
          uid: item.file_path,
          name: item.file_name,
          status: "done",
          url: "",
          response: {
            status: 1,
            fileObj: {
              base: item.file_name,
            },
            file_path: item.file_path,
          },
        });
      }
    }
  },
  methods: {
    handleChange(info) {
      let fileList = [...info.fileList];

      // 2. read from response and show file link
      fileList = fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;

      const files = [];
      for (let item of this.fileList) {
        if (item.status == "done" && item.response.status == 1) {
          files.push({
            file_path: item.response.file_path,
            file_name: item.response.fileObj.base,
          });
        }
      }

      console.log(files, "--------- 上传成功的文件 ---------");

      if(this.mult){
        this.$emit("callback", files);
      }
      else{
        if(files.length == 0){
          this.$emit("callback", {
            file_name: '',
            file_path: ''
          });
        }
        else{
          this.$emit("callback", files[0]);
        }
      }
      
      return;
    },
  },
};
</script>