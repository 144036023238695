<template>
  <div class="page">
    <div class="ctrl">
      <a-form layout="inline">
        <a-form-item>
          <a-select v-model="options.unit_id" style="width: 240px" @change="getList(1)">
            <a-select-option :value="0">请选择分所</a-select-option>
            <a-select-option
              v-for="(unit, index) in unitList"
              :key="index"
              :value="unit.id"
              >{{ unit.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select v-model="options.spe" style="width: 240px" @change="getList(1)">
            <a-select-option value="">请选择专业</a-select-option>
            <a-select-option
              v-for="(item, index) in speList"
              :key="index"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          ><a-input placeholder="姓名" v-model="options.name"
        /></a-form-item>
        <a-form-item
          ><a-input placeholder="手机" v-model="options.tel"
        /></a-form-item>
        <a-form-item
          ><a-button type="primary" @click="getList"
            >搜索</a-button
          ></a-form-item
        >
      </a-form>
    </div>

    <div class="stat">共{{ page.amount }}条</div>

    <a-table
      class="sec"
      size="small"
      :rowKey="(record) => record.id"
      bordered
      :columns="columns"
      :data-source="list"
      :pagination="false"
    >
      <template slot="spe" slot-scope="text, record">
        <a-tag v-for="(item, index) in record.spe" :key="index">{{item}}</a-tag>
      </template>

      <template slot="qr_" slot-scope="text, record">
        <a-icon v-if="record.qr" type="check" />
      </template>

      <template slot="bd" slot-scope="text, record">
        <a-icon v-if="record.openid" type="check" />
      </template>
      
      <template slot="action" slot-scope="text, record">
        <router-link :to="'/user/info?id=' + record.id">
          <a-button size="small" type="primary">详情</a-button></router-link
        >
        <a-divider type="vertical" />
        <router-link :to="'/user/update?id=' + record.id"
          ><a-button size="small" type="primary">编辑</a-button></router-link
        >
        <a-divider type="vertical" />
        <a-button size="small" @click="getQr(record)">小程序码</a-button>
        <a-divider type="vertical" />
        <a-button size="small" @click="getQr2(record)">二维码</a-button>
        <a-divider type="vertical" />
        <a-popconfirm
          title="确定要删除吗？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="updateStatus(record.id, 0)"
        >
          <a-button size="small" type="danger">删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>

    <a-pagination
      class="pagination"
      showQuickJumper
      v-model="page.now_page"
      :defaultPageSize="page.page_size"
      :total="page.amount"
      @change="changePage"
    />

    <a-modal
      v-model="qrModal.show"
      :title="qrModal.name + '的名片码'"
      @ok="qrModal.show = false"
    >
      <img class="qr" :src="qrModal.qr" alt="" />
      <p class="tips">将名片码发给用户后要求立即绑定，否则可能会被其他人绑定</p>
    </a-modal>

    <a-modal
      v-show="qrModal2.show"
      :title="qrModal2.name + '的名片码'"
      @ok="qrModal2.show = false"
    >
      <qrcode-vue :value="qrModal2.qr" :size="470" level="H" />
      <p class="tips">将名片码发给用户后要求立即绑定，否则可能会被其他人绑定</p>

      <div id="q"></div>
    </a-modal>
  </div>
</template>

<style lang='scss' scoped>
.qr {
  width: 400px;
  margin: 0 auto;
  display: block;
}
.tips {
  color: red;
  text-align: center;
}
</style>

<script>
// import QrcodeVue from "qrcode.vue";

const columns = [
  {
    title: "#",
    dataIndex: "id",
  },
  {
    title: "律所",
    dataIndex: "unit_name",
  },
  {
    title: "姓名",
    dataIndex: "name",
  },
  {
    title: "小程序码",
    scopedSlots: {
      customRender: "qr_",
    },
  },
  {
    title: "绑定",
    scopedSlots: {
      customRender: "bd",
    },
  },
  {
    title: "职务",
    dataIndex: "position",
  },
  {
    title: "电话",
    dataIndex: "tel",
  },
  {
    title: "邮箱",
    dataIndex: "email",
  },
  {
    title: "微信号",
    dataIndex: "wechat",
  },
  {
    title: "专业",
    scopedSlots: {
      customRender: "spe",
    },
  },
  {
    title: "操作",
    scopedSlots: {
      customRender: "action",
    },
  },
];

export default {
  // components: {
  //   QrcodeVue,
  // },
  data() {
    return {
      speList: [],
      unitList: [],
      officeList: [],
      columns,
      list: [],
      options: {
        unit_id: 0,
        spe: '',
        name: "",
        tel: "",
      },
      page: {
        now_page: 1,
        page_size: 50,
        amount: 0,
      },
      qrModal: {
        show: false,
        name: "",
        qr: "",
      },
      qrModal2: {
        show: false,
        name: "",
      },
    };
  },
  created() {
    this.speList = [
      "公司治理与并购",
      "证券与资本市场",
      "私募股权与投资基金",
      "企业清算、破产及并购重组",
      "银行与金融",
      "政府法律事务与行政诉讼",
      "刑事辩护与代理",
      "公益事业",
      "房地产、建设工程与基础设施",
      "企业合规与风险控制",
      "农业与新农村建设",
      "民事综合类业务",
      "婚姻家事与财富传承",
      "医疗健康",
      "境外投资与外商投资",
      "海事海商",
      "知识产权、互联网与信息技术",
      "国际贸易业务",
      "劳动争议与人力资源",
      "新能源与环境保护",
      "安全生产及应急管理",
    ];
    this.getUnitList();
    this.page.now_page = Number(this.$route.query.page) || 1;
    this.getList();
  },
  watch: {
    "$route.query.page"() {
      this.page.now_page = Number(this.$route.query.page) || 1;
      this.getList();
    },
  },
  methods: {
    async getUnitList() {
      const r = await this.$axios.get("/unit/getAllList");

      if (r.status == 1) {
        this.unitList = r.list;
      }
    },
    changePage(e) {
      this.$router.push("/user/list?page=" + e);
    },

    async getList(e) {
      if(e) this.page.now_page = 1;
      const options = { ...this.options };
      options.page = this.page.now_page || 1;

      console.log(options, 222);
      const r = await this.$axios.get("/user/getList", {
        params: options,
      });

      if (r.status != 1) return;
console.log(r);
      this.list = r.list;
      this.page.amount = r.amount;
    },

    async updateStatus(id, status) {
      const r = await this.$axios.post("/user/updateStatus", {
        id,
        status,
      });

      if (r.status == 1) this.getList();
    },

    async getQr(item) {
      const r = await this.$axios.get("/user/getQr?uid=" + item.id);
      console.log(r);

      this.qrModal = {
        show: true,
        name: item.name,
        qr: r.filepath,
      };
    },

    async getQr2(item) {
      this.$router.push("/user/qr?uid=" + item.id);
      // this.qrModal2 = {
      //   show: true,
      //   name: item.name,
      //   qr: "https://mingpian.imate3.cn/vcard?uid=" + item.id,
      // };
    },
  },
};
</script>