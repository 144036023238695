<template>
  <div class="page">

  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
export default {
  data() {
    return {
      
    }
  },
  beforeCreate () {},
  created () {},
  mounted () {},
  methods: {}
}
</script>