<template>
  <div class="flex-page">
    <a-form
      class="form"
      :label-col="formItemLayout.labelCol"
      :wrapper-col="formItemLayout.wrapperCol"
    >
      <a-form-item label="全称">
        <a-input type="text" style="width: 600px" v-model="form.name"></a-input>
      </a-form-item>

      <a-form-item label="Logo">
        <UploadFile @callback="uploaded" :mult="false" folder="unit" />
        <img style="height: 40px" v-if="form.logo" :src="API + form.logo" alt="">
      </a-form-item>

      <a-form-item label="电话">
        <a-input style="width: 600px" type="text" v-model="form.tel"></a-input>
      </a-form-item>
      <a-form-item label="邮件">
        <a-input
          style="width: 600px"
          type="text"
          v-model="form.email"
        ></a-input>
      </a-form-item>

      <a-form-item label="网址">
        <a-input
          style="width: 600px"
          type="text"
          v-model="form.website"
        ></a-input>
      </a-form-item>

      <a-form-item label="地址">
        <a-input style="width: 600px" type="text" v-model="form.addr"></a-input>
      </a-form-item>

      <a-form-item label="坐标">
        <a-button icon="compass" @click="show_pos_picker = true"></a-button>
        <PosPicker v-if="show_pos_picker" @callback="setPos" />
        <span>{{form.lat}},{{form.lng}}</span>
      </a-form-item>

      <a-form-item label="介绍">
        <Editor
          v-if="show_editor"
          @callback="updateEditor"
          :data="form.desc"
          folder="unit"
        />
      </a-form-item>
    </a-form>
    <div class="buttons">
      <a-button type="primary" @click="sub">提交保存</a-button>
    </div>
  </div>
</template>

<script>
import UploadFile from "../../components/uploadFile";
import Editor from "../../components/editor";
import PosPicker from '../../components/posPicker.vue'

export default {
  components: { UploadFile, Editor, PosPicker },
  data() {
    return {
      formItemLayout: {
        labelCol: {
          span: 3,
        },
        wrapperCol: {
          span: 20,
        },
      },
      show_pos_picker: false,
      show_editor: true,
      form: {
        name: "",
        logo: '',
        tel: '',
        email: '',
        addr: "",
        lat: '',
        lng: '',
        website: '',
        desc: ""
      },
    };
  },
  methods: {
    async sub() {
      const that = this;
      const r = await that.$axios.post("/unit/create", this.form);

      if (r.status == 1) {
        this.$router.push("/unit/list");
      }
    },

    uploaded(file) {
      console.log(file);
      this.form.logo = file.file_path
    },

    updateEditor(data) {
      this.form.desc = data;
    },

    setPos (pos) {
      if(!this.form.addr) this.form.addr = pos.poiaddress;
      this.form.lat = pos.latlng.lat;
      this.form.lng = pos.latlng.lng;
      this.show_pos_picker = false;
    }
  },
};
</script>