<template>
  <a-modal
    title="选择地址"
    okText="确定"
    :visible="true"
    cancelText="取消"
    @ok="callback"
    @cancel="callback"
  >
    <iframe
      id="mapPage"
      width="100%"
      height="600px"
      frameborder="0"
      :src="`https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=${$config.mapKey}&referer=touchstone`"
    >
    </iframe>
  </a-modal>
</template>

<style lang='scss' scoped>
</style>

<script>
export default {
  data() {
    return {
      key: this.$config.AK,
      location: {}
    };
  },
  mounted() {
    const that = this;
    window.addEventListener(
      "message",
      function (event) {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        const location = event.data;
        if (location && location.module == "locationPicker") {
          //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
          that.location = location;
          that.callback()
        }
      },
      false
    );
  },
  methods: {
    callback () {
      console.log(this.location, 111122222);
      this.$emit('callback', this.location)
    },
  },
};
</script>