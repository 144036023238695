<template>
  <div class="flex-page">
    <a-form
      class="form"
      :label-col="formItemLayout.labelCol"
      :wrapper-col="formItemLayout.wrapperCol"
    >
      <a-form-item label="名称">{{info.name}}</a-form-item>
      <a-form-item label="Logo">
        <img v-if="info.logo" style="height: 60px" :src="this.API + info.logo" alt="">
      </a-form-item>
      <a-form-item label="电话">
        {{info.tel}}
      </a-form-item>
      <a-form-item label="邮箱">
        {{info.email}}
      </a-form-item>
      <a-form-item label="网址">
        {{info.website}}
      </a-form-item>

      </a-form-item>
      <a-form-item label="地址">{{info.addr}}</a-form-item>
      <a-form-item label="坐标">{{info.lat}},{{info.lng}}</a-form-item>
      <a-form-item label="介绍">
        <div v-html="info.desc"></div>
      </a-form-item>
    </a-form>
    <div class="buttons">
      <a-button type="primary" @click="update">编辑</a-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form {
  padding: 40px;
}
.sec{
  margin: 15px;
}
img{
  max-width: 100%;
}
</style>

<script>
export default {
  data() {
    return {
      formItemLayout: {
        labelCol: {
          span: 3,
        },
        wrapperCol: {
          span: 20,
        },
      },
      info: {},
    };
  },
  async created () {
    const id = this.$route.query.id;
    const r = await this.$axios.get('/unit/getInfo?id=' + id);
    if(r.status == 1){
      this.info = r.info
    }
  },
  methods: {
    update () {
      this.$router.push(`/unit/update?id=${this.info.id}`)
    }
  },
};
</script>