<template>
  <div class="page">
    <div id="qrcode"></div>
  </div>
</template>

<style lang='scss' scord>
.qrcode{
  // margin: 100px auto;
}
</style>

<script>
import QRCode from "easyqrcodejs";

export default {
  data() {
    return {
      uid: null
    };
  },
  mounted() {
    this.uid = this.$route.query.uid;
    new QRCode(document.getElementById("qrcode"), {
      text: 'https://mingpian.imate3.cn/vcard?uid=' + this.uid,
      width: 1000,
      height: 1000,
      correctLevel : QRCode.CorrectLevel.H,
      logo: 'logo3.png'
    })
  },
};
</script>