<template>
  <div class="flex-page">
    <a-form
      class="form"
      :label-col="formItemLayout.labelCol"
      :wrapper-col="formItemLayout.wrapperCol"
    >
      <a-form-item label="姓名">{{info.name}}</a-form-item>
      <a-form-item label="职务">{{info.position}}</a-form-item>
      <a-form-item label="照片">
        <img v-if="info.photo" style="height: 100px" :src="API + info.photo" alt="">
      </a-form-item>
      </a-form-item>
      <a-form-item label="专业">
        <a-tag v-for="(tag, index) in info.spe" :key="index">{{tag}}</a-tag>
      </a-form-item>
      <a-form-item label="手机">{{info.tel}}</a-form-item>
      <a-form-item label="邮箱">{{info.email}}</a-form-item>
      <a-form-item label="微信">{{info.wechat}}</a-form-item>
      <a-form-item label="微信二维码">
        <img v-if="info.wechat_qr" style="height: 100px" :src="API + info.wechat_qr" alt="">
      </a-form-item>
      <a-form-item label="简介">
        <div v-html="info.desc"></div>
      </a-form-item>
    </a-form>
    <div class="buttons">
      <a-button type="primary" @click="update">更新信息</a-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form {
  padding: 40px;
}
.sec{
  margin: 15px;
}
img{
  max-width: 100%;
}
</style>

<script>
export default {
  data() {
    return {
      formItemLayout: {
        labelCol: {
          span: 3,
        },
        wrapperCol: {
          span: 20,
        },
      },
      cateList: [],
      info: {},
    };
  },
  async created () {
    const id = this.$route.query.id;
    const r = await this.$axios.get('/user/getInfo?id=' + id);
    console.log(r);
    if(r.status == 1){
      this.info = r.info
    }
  },
  methods: {
    update () {
      this.$router.push(`/user/update?id=${this.info.id}`)
    }
  },
};
</script>