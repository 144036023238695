<template>
  <div>
    <div v-if="$route.meta.layout == 'simple'">
      <a-config-provider :locale="locale">
        <router-view />
      </a-config-provider>
    </div>

    <div id="app" v-else>
      <Aside />
      <div class="wrap">
        <Header />
        <main class="container">
          <a-config-provider :locale="locale">
            <router-view />
          </a-config-provider>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Aside from "./components/layout/aside";
import Header from "./components/layout/header";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
  components: { Aside, Header },
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>