<template>
  <div class="page">
    <a-table
      class="sec"
      size="small"
      :rowKey="(record) => record.id"
      bordered
      :columns="columns"
      :data-source="list"
      :pagination="false"
    >
      <template slot="action" slot-scope="text, record">
        <router-link :to="'/unit/info?id=' + record.id">详情</router-link>
        <a-divider type="vertical" />
        <router-link :to="'/unit/update?id=' + record.id">编辑</router-link>
        <a-divider type="vertical" />
        <a-popconfirm
          title="确定要删除吗？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="updateStatus(record.id, 0)"
        >
          <a href="javascript:;">删除</a>
        </a-popconfirm>
      </template>
    </a-table>

    <a-pagination
      class="pagination"
      showQuickJumper
      v-model="page.now_page"
      :defaultPageSize="page.page_size"
      :total="page.amount"
      @change="changePage"
    />
  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
  },
  {
    title: "名称",
    dataIndex: "name",
  },
  {
    title: "电话",
    dataIndex: "tel",
  },
  {
    title: "地址",
    dataIndex: "addr",
  },
  {
    title: "邮箱",
    dataIndex: "email",
  },
  {
    title: "操作",
    scopedSlots: {
      customRender: "action",
    },
  },
];

export default {
  data() {
    return {
      columns,
      list: [],
      page: {
        now_page: 1,
        page_size: 50,
        amount: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    changePage(e) {
      this.page.now_page = e;
      this.getList();
    },

    async getList() {
      const page = this.page.now_page;
      const r = await this.$axios.get("/unit/getList", {
        params: {
          page,
        },
      });

      if (r.status != 1) return;

      this.list = r.list;
      this.page.amount = r.amount;
    },

    async updateStatus(id, status) {
      const r = await this.$axios.post("/unit/updateStatus", {
        id,
        status,
      });

      if (r.status == 1) this.getList();
    },
  },
};
</script>