<template>
  <div class="flex-page">
    <a-form
      class="form"
      :label-col="formItemLayout.labelCol"
      :wrapper-col="formItemLayout.wrapperCol"
    >
      <a-form-item label="律所">
        <a-select v-model="form.unit_id" style="width: 400px">
          <a-select-option :value="0">请选择</a-select-option>
          <a-select-option
            v-for="(unit, index) in unitList"
            :key="index"
            :value="unit.id"
            >{{ unit.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="姓名">
        <a-input type="text" style="width: 600px" v-model="form.name"></a-input>
      </a-form-item>
      <a-form-item label="职务">
        <a-input
          type="text"
          style="width: 600px"
          v-model="form.position"
        ></a-input>
      </a-form-item>
      <a-form-item label="照片">
        <UploadFile @callback="uploaded" :mult="false" folder="user" />
        <img
          style="height: 40px"
          v-if="form.photo"
          :src="API + form.photo"
          alt=""
        />
      </a-form-item>
      <a-form-item label="专业">
        <a-select mode="multiple" v-model="spe" style="width: 400px">
          <a-select-option v-for="(item, index) in speList" :key="index">
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="手机">
        <a-input style="width: 600px" type="text" v-model="form.tel"></a-input>
      </a-form-item>
      <a-form-item label="邮箱">
        <a-input
          style="width: 600px"
          type="text"
          v-model="form.email"
        ></a-input>
      </a-form-item>
      <a-form-item label="微信">
        <a-input
          style="width: 600px"
          type="text"
          v-model="form.wechat"
        ></a-input>
      </a-form-item>
      <a-form-item label="微信二维码">
        <UploadFile @callback="uploaded2" :mult="false" folder="user" />
        <img
          style="height: 40px"
          v-if="form.wechat_qr"
          :src="API + form.wechat_qr"
          alt=""
        />
      </a-form-item>
      <a-form-item label="个人简介">
        <Editor
          v-if="show_editor"
          @callback="updateEditor"
          :data="form.desc"
          folder="seller"
        />
      </a-form-item>
    </a-form>
    <div class="buttons">
      <a-button type="primary" @click="sub">提交保存</a-button>
    </div>
  </div>
</template>

<script>
import UploadFile from "../../components/uploadFile";
import Editor from "../../components/editor";

export default {
  components: { UploadFile, Editor },
  data() {
    return {
      formItemLayout: {
        labelCol: {
          span: 3,
        },
        wrapperCol: {
          span: 20,
        },
      },
      formItemLayout: {
        labelCol: {
          span: 3,
        },
        wrapperCol: {
          span: 20,
        },
      },
      show_editor: false,
      unitList: [],
      speList: [],
      spe: [],
      form: {},
    };
  },
  async created() {
    this.speList = [
      "公司治理与并购",
      "证券与资本市场",
      "私募股权与投资基金",
      "企业清算、破产及并购重组",
      "银行与金融",
      "政府法律事务与行政诉讼",
      "刑事辩护与代理",
      "公益事业",
      "房地产、建设工程与基础设施",
      "企业合规与风险控制",
      "农业与新农村建设",
      "民事综合类业务",
      "婚姻家事与财富传承",
      "医疗健康",
      "境外投资与外商投资",
      "海事海商",
      "知识产权、互联网与信息技术",
      "国际贸易业务",
      "劳动争议与人力资源",
      "新能源与环境保护",
      "安全生产及应急管理",
    ];

    const id = this.$route.query.id;
    const r = await this.$axios.get("/user/getInfo?id=" + id);
    console.log(r.info.spe);
    if (r.status == 1) {
      this.form = r.info;
      this.show_editor = true;
    }

    for (let item of this.form.spe) {
      for (let i = 0; i < this.speList.length; i++) {
        if (item == this.speList[i]) this.spe.push(i);
      }
    }

    this.getUnitList();
  },
  methods: {
    async getUnitList() {
      const r = await this.$axios.get("/unit/getAllList");

      if (r.status == 1) {
        this.unitList = r.list;
      }
    },
    async sub() {
      this.form.spe = [];
      for(let index of this.spe){
        this.form.spe.push(this.speList[index])
      }
      console.log(this.form);
      const r = await this.$axios.post("/user/update", this.form);

      if (r.status == 1) {
        this.$router.push("/user/list");
      }
    },

    uploaded(file) {
      this.form.photo = file.file_path;
    },

    uploaded2(file) {
      this.form.wechat_qr = file.file_path;
    },

    updateEditor(data) {
      this.form.desc = data;
    },
  },
};
</script>