import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import store from '../store/index'

const routes = [
  // =================== 首页 ===================
  {
    path: '/',
    name: 'index',
    meta: {
      title: '首页'
    },
    component: require('@/pages/index').default
  },

  // =================== 登录 ===================
  {
    path: '/admin/login',
    name: 'login',
    meta: {
      layout: 'simple'
    },
    component: require('@/pages/admin/login').default
  },
  {
    path: '/admin/updatePsw',
    name: 'updatePsw',
    meta: {
      title: '修改密码'
    },
    component: require('@/pages/admin/updatePsw').default
  },

  // =================== 律所管理 ===================
  {
    path: '/unit/list',
    name: 'unitList',
    meta: {
      title: '律所管理'
    },
    component: require('@/pages/unit/list').default
  },

  {
    path: '/unit/create',
    name: 'unitCreate',
    meta: {
      title: '添加律所'
    },
    component: require('@/pages/unit/create').default
  },

  {
    path: '/unit/info',
    name: 'unitInfo',
    meta: {
      title: '律所资料'
    },
    component: require('@/pages/unit/info').default
  },

  {
    path: '/unit/update',
    name: 'unitUpdate',
    meta: {
      title: '编辑资料'
    },
    component: require('@/pages/unit/update').default
  },

  // =================== 用户管理 ===================
  {
    path: '/user/create',
    name: 'userCreate',
    meta: {
      title: '添加用户'
    },
    component: require('@/pages/user/create').default
  },
  {
    path: '/user/list',
    name: 'userList',
    meta: {
      title: '用户列表'
    },
    component: require('@/pages/user/list').default
  },
  {
    path: '/user/info',
    name: 'userInfo',
    meta: {
      title: '用户详情'
    },
    component: require('@/pages/user/info').default
  },
  {
    path: '/user/update',
    name: 'userUpdate',
    meta: {
      title: '更新用户'
    },
    component: require('@/pages/user/update').default
  },
  {
    path: '/user/qr',
    name: 'userQr',
    meta: {
      title: '二维码'
    },
    component: require('@/pages/user/qr').default
  },

]

const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  const navs = store.state.navs;
  let selectedKeys = []; //当前选中菜单
  let openKeys = []; //当前展开菜单
  const breadcrumbs = []; //面包屑

  const now_path = to.path;
  let nowNav; //当前正在运算的nav

  for(let nav of navs){
    if(nav.path == now_path || nav.route == now_path){
      nowNav = nav;
      break
    }
  }

  if(!nowNav) return next()

  const getFather = (id) => {
    for (let nav of navs) {
      if (nav.id == id) return nav
    }
  }

  // 根据father层层查找
  const hello = (nowNav) => {
    breadcrumbs.unshift(nowNav.title);
    if(nowNav.class == 2 && nowNav.father) selectedKeys.unshift(nowNav.id);
    if(nowNav.class == 1 && !nowNav.father) openKeys.unshift(nowNav.id);

    if(nowNav.father !== 0){
      const father = getFather(nowNav.father);
      hello(father)
    }
  }

  if(nowNav.class == 1){
    selectedKeys.unshift(nowNav.id);
    breadcrumbs.unshift(nowNav.title);
  }
  else{
    hello(nowNav)
  }

  store.commit('setActiveMenu', {
    selectedKeys,
    openKeys,
    breadcrumbs
  })
  
  next()
})

// 避免跳转到当前网址的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router
