<template>
  <div id="editor"></div>
</template>

<style lang='scss' scoped>
</style>

<script>
export default {
  props: ['data', 'folder'],
  data() {
    return {}
  },
  mounted() {
    const E = require("wangeditor");
    const that = this;
    const editor = new E("#editor");
    editor.config.height = 500;
    editor.config.zIndex = 0;

     // 上传图片到服务器
    editor.config.uploadImgServer = this.API + "/file/uploadFiles";
    editor.config.uploadImgParams = {
      folder: this.folder || 'other',
      name: 'files'
    };
    editor.config.uploadImgMaxLength = 9; //一次只允许上传1张图片
    editor.config.uploadImgHooks = {
      customInsert: function (insertImgFn, result) {

        console.log(result, 888888888822);
        if(result.status == 1){
          for (let img of result.fileList) {
            insertImgFn(that.API + img.url)
          }
        }
      },
    };
    editor.config.uploadImgHeaders = {
      token: this.$store.state.token,
    };

    editor.config.onchange = function (html) {
      // html 即变化之后的内容
      that.callback(html)
    };
    editor.create();

    editor.txt.html(this.data)
    return;
  },
  methods: {
    callback (data) {
      this.$emit("callback", data);
    }
  }
}
</script>